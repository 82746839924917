.Loading {
  @apply fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center;

  .dot {
    @apply absolute top-0 mt-1 w-3 h-3 rounded-full bg-stone-400;
  }
  .loaderDots div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loaderDots div:nth-child(1) {
    left: 8px;
    animation: loaderDots1 0.6s infinite;
  }
  .loaderDots div:nth-child(2) {
    left: 8px;
    animation: loaderDots2 0.6s infinite;
  }
  .loaderDots div:nth-child(3) {
    left: 32px;
    animation: loaderDots2 0.6s infinite;
  }
  .loaderDots div:nth-child(4) {
    left: 56px;
    animation: loaderDots3 0.6s infinite;
  }
  @keyframes loaderDots1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loaderDots3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes loaderDots2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
